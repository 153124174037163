<template>
  <div>
    <div>
      <div class="control is-flex is-align-items-center"
        :class="[notificationClass, textclass]">
        <div class="mr-4">
          <span class="icon">
            <i class="mdi mdi-36px"
              :class="icon" />
          </span>
        </div>
        <slot name="text-content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordNotification',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      notificationClass: 'noti-primary',
      textclass: 'has-text-primary',
      icon: 'mdi-check-circle-outline'
    }
  },
  created() {
    this.getClass(this.type)
  },
  methods: {
    getClass(type) {
      switch (type) {
        case 'success':
          this.notificationClass = 'noti-primary'
          this.icon = 'mdi-check-circle-outline'
          this.textclass = 'has-text-primary'
          break
        case 'warning':
          this.notificationClass = 'noti-warning'
          this.icon = 'mdi-bell-ring-outline'
          this.textclass = 'has-text-warning'
          break
        case 'danger':
          this.notificationClass = 'noti-danger'
          this.icon = 'mdi-alert-circle-outline'
          this.textclass = 'has-text-danger'
          break
        default:
          break
      }
    }
  }
}
</script>

<style>
.noti-warning {
  border: 1px solid #f9bf00;
  border-radius: 4px;
  background-color: #fffcf2;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}
.noti-danger {
  border: 2px solid #d73654;
  border-radius: 4px;
  background-color: #fff6f8;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}
.noti-primary {
  border: 2px solid #3291ef;
  border-radius: 4px;
  background-color: #f0f8ff;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}
</style>